


















import { SearchBuilder } from "@/builder";
import { debounceProcess } from "@/helpers/debounce";
import { useLocation } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { WarehouseLocationResponseDto } from "@/models/interface/location";
import { LabelInValue } from "@/types";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class SelectRack extends Vue {
  @Prop({ required: true, default: undefined })
  readonly value!: LabelInValue;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ required: false, type: String, default: "" })
  branchId!: string;

  @Prop({ required: false, type: String, default: "name" })
  valueAs!: keyof WarehouseLocationResponseDto;

  @Prop({ required: false, type: String, default: "" })
  warehouseId!: string;

  options: Option<WarehouseLocationResponseDto>[] = [];
  loading = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    const params = this.buildParams();
    this.fetchOptions(params);
  }

  @Watch("branchId")
  onChangeBranchId(): void {
    const params = this.buildParams();
    this.fetchOptions(params);
  }

  @Watch("warehouseId")
  onChangeWarehouse(): void {
    const params = this.buildParams();
    this.fetchOptions(params);
  }

  fetchOptions(
    params: RequestQueryParamsModel = new RequestQueryParams()
  ): void {
    const { findAll, toOptions } = useLocation();
    this.loading = true;
    findAll(params)
      .then(({ data }) => {
        this.options = toOptions(data, this.valueAs);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e?: LabelInValue): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(val = ""): void {
    const { searchBy } = useLocation();
    const builder = new SearchBuilder();
    const query: Array<string> = [];
    const params = new RequestQueryParams();
    const { search: defQuery } = this.buildParams();

    if (val) {
      query.push(searchBy({ name: val }));
    }

    if (defQuery) {
      query.unshift(defQuery);
    }

    params.search = query.join(builder.AND);

    this.fetchOptions(params);
  }

  findOption(
    value: LabelInValue | undefined
  ): Option<WarehouseLocationResponseDto> | undefined {
    return this.options.find(e => value?.key === e.value);
  }

  buildParams(): RequestQueryParams {
    const builder = new SearchBuilder();
    const queries: Array<string> = [];
    const params = new RequestQueryParams();

    if (this.branchId) {
      queries.push(
        builder
          .push(["warehouse.branchWarehouse.secureId", this.branchId])
          .build()
      );
    }

    if (this.warehouseId) {
      queries.push(
        builder.push(["warehouse.secureId", this.warehouseId]).build()
      );
    }

    params.search = queries.join(builder.AND);
    return params;
  }
}
